<script setup lang="ts">
const props = defineProps<{
  providers?: ('google' | 'github' | string)[]
}>()

const PROVIDERS = {
  google: {
    label: 'Continuar con Google',
    icon: 'i-logos-google-icon',
    color: 'white' as const,
    href: '/api/auth/google',
    external: true, // required for /auth (server) routes
  },
  github: {
    label: 'Continuar con GitHub',
    icon: 'i-mdi-github',
    color: 'white' as const,
    href: '/api/auth/github',
    external: true, // required for /auth (server) routes
  },
}

const providers = computed(() => props.providers?.map(provider => PROVIDERS?.[provider] ?? []))

const loading = ref(false)

function open(href: string) {
  loading.value = true
  window.location.href = href
}
</script>

<template>
  <div class="grid gap-2">
    <UButton
      v-for="provider in providers"
      :key="provider.label"
      :icon="provider.icon"
      :loading="loading"
      :disabled="loading"
      external
      block
      color="gray"
      size="lg"
      @click="open(provider.href)"
    >
      {{ provider.label }}
    </UButton>
  </div>
</template>
